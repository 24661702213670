.Abs-form {
  position: relative;
  background: var(--Light);

  padding: 2rem 1rem;
}
.title-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.Abs-form-title {
  color: var(--Pink);
  font-size: 2rem;
}

.form-sect-wrapper {
  width: 100%;
  margin: 1.5rem 0;
}
.Abs-label {
  display: inline-block;
  border: 1px solid var(--Gray);
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 5px 10px;
}
.Abs-label input {
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
}
.top-sect-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  border: 2px solid var(--Gray);
  padding: 2rem;
}
.Abs-photo-label {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--Gray);
  background: #6c6b6b19;

  width: 200px;
  height: 200px;
  margin: auto;
}
.Abs-photo-img {
  width: 100%;
  height: 100%;
}
.photo-btn-wrapper {
  position: absolute;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.photo-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 80px;

  border-radius: 50%;
  background: var(--Black);
  color: var(--Light);
  opacity: 0.4;

  font-size: 1.5rem;
  font-weight: 700;
}
.photo-btn:hover {
  opacity: 0.8;
}
.Abs--details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
}
.down-sect-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  margin-top: 3rem;
  border: 2px solid var(--Gray);
  padding: 2rem;
}

.date-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.date-wrapper label {
  width: 48%;
}
.bottom-sect-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;

  padding-top: 10px;
}
.terms-label {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 12px;

  text-transform: capitalize;
}
.terms-input {
  width: 18px;
  height: 18px;
}
.terms-text:hover {
  cursor: pointer;
  color: var(--Pink);
}
.register-btn {
  padding: 10px 15px;
  margin-top: 10px;
  border-radius: 4px;

  font-size: 1rem;
  font-weight: 700;

  color: var(--Light);
  background: var(--Pink);
  text-decoration: none;
  outline: none;
}
.register-btn:hover {
  cursor: pointer;
  opacity: 0.7;
}

@media screen and (min-width: 500px) {
  .Abs-form {
    padding: 2rem;
  }
  .form-sect-wrapper {
    border: 2px solid var(--Gray);
    padding: 2rem;
  }
  .top-sect-wrapper {
    display: flex;
    flex-direction: row;
    border: none;
    padding: 0;
  }
  .Abs-photo-label {
    width: 180px;
    height: 180px;
  }
  .Abs--details {
    width: 50%;
  }
  .down-sect-wrapper {
    border: none;
    padding: 0;
  }
  .date-wrapper {
    width: 100%;
    gap: 3rem;
  }
  .date-wrapper label {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .Abs-form {
    padding: 3rem 4rem;
  }
}
@media screen and (min-width: 1024px) {
  /* modal section */
}
@media screen and (min-width: 1024px) and (max-height: 800px) {
  .form-sect-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;

    border: none;
    padding: 0rem;
  }

  .top-sect-wrapper {
    border: 2px solid var(--Gray);
    padding: 2rem;
  }
  .down-sect-wrapper {
    margin-top: 0rem;
    border: 2px solid var(--Gray);
    padding: 2rem;
  }
}
